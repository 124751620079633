<template>
    <div
        :class="[
            disabled ? 'bg-gray-100' : 'bg-white',
            error && !props.valid ? 'border-red-300' : 'border-gray-300',
           {'border-green-500 md:border-gray-300':  props.valid && !error },
            'relative border rounded-full px-8 py-2 shadow-sm focus-within:ring-1 focus-within:ring-indigo-600 focus-within:border-indigo-600'
        ]">
        <label
            :for="name"
            class="block text-xs text-gray-500"
        >
            {{ label }}
        </label>
        <input
            ref="input"
            :id="name"
            v-model="value"
            class="block w-full border-0 p-0 text-gray-900 placeholder-gray-500 focus:ring-0 focus-visible:outline-none sm:text-lg"
            :class="disabled ? 'bg-gray-100' : 'bg-white'"
            v-bind="$attrs"
            :disabled="disabled"
        />
        <div v-if="props.error"
             class="md:absolute transform md:translate-y-1/2 bg-white bottom-0 left-6 px-2 text-xs text-red-600">
            {{ props.error }}
        </div>
        <LoadingSpinner v-if="props.loading"
                        size="xs"
                        class="absolute top-0 right-4 transform translate-y-1/2"
        ></LoadingSpinner>
        <CheckIcon v-else-if="props.valid"
                   class="absolute hidden md:block top-0 right-4 transform translate-y-1/2 h-6 w-6 md:h-8 md:w-8 text-green-500 opacity-50"
                   aria-hidden="true"/>
    </div>

</template>


<script setup>
import {CheckIcon} from '@heroicons/vue/solid'
import {computed, onMounted, ref} from "vue";
import {IMask} from 'vue-imask';
import LoadingSpinner from "./LoadingSpinner";


const props = defineProps({
    label: String,
    description: String,
    disabled: Boolean,
    error: String,
    modelValue: [String, Date],
    default: [String, Date],
    valid: Boolean,
    mask: Object,
    name: String,
    loading: {
        type: Boolean,
        default: false
    }
})

const emit = defineEmits(['update:modelValue']);
const input = ref(null)
let mask;

const value = computed({
    get() {
        return props.modelValue
    },
    set(value) {
        setTimeout(() => {
            emit('update:modelValue', mask ? mask.value : value);
        });
    }
})

onMounted(() => {
    if (props.mask) {
        mask = IMask(input.value, props.mask)
        mask.on("accept", function () {
            value.value = mask.value;
        });
    }

    if(props.default) {
        value.value = props.default
    }
})
</script>

import annualPayment from "./annualPayment";
import birthdayCode from "./birthdayCode";
import callbackService from "./callbackService";
import cancelContract from "./cancelContract";
import contactForm from "./contactForm";
import contactFormBusiness from "./contactFormBusiness";
import carPower from "./carPower";
import deregisterApartment from "./deregisterApartment";
import registerWater from "./registerWater";
import standpipeRental from "./standpipeRental";
import streetLights from "./streetLights";
import tutorialDefault from "./tutorialDefault"
import employeeCoupons from "./employeeCoupons";
import contractExtension from "./contractExtension";
import customerAdvisoryBoard from "./customerAdvisoryBoard"
import thgQuotePrivate from "./thgQuotePrivate"
import thgQuoteBusiness from "./thgQuoteBusiness"
import newsletterForm from "./newsletterForm";
import businessNewsletterForm from "./businessNewsletterForm";
import financialSupportForm from "./financialSupportForm";
import heatPumpForm from "./heatPumpForm";
import sponsoringForm from "./sponsoringForm";



export default {
    annual_payment: annualPayment,
    birthday_code: birthdayCode,
    employee_coupons: employeeCoupons,
    callback_service: callbackService,
    cancel_contract: cancelContract,
    contact_form: contactForm,
    contact_form_business: contactFormBusiness,
    car_power: carPower,
    deregister_apartment: deregisterApartment,
    register_water: registerWater,
    standpipe_rental: standpipeRental,
    street_lights: streetLights,
    tutorial: tutorialDefault,
    contract_extension: contractExtension,
    customer_advisory_board: customerAdvisoryBoard,
    thg_quote_private: thgQuotePrivate,
    thg_quote_business: thgQuoteBusiness,
    newsletter: newsletterForm,
    business_newsletter: businessNewsletterForm,
    financial_support: financialSupportForm,
    heat_pump: heatPumpForm,
    sponsoring: sponsoringForm,
}

<template>
    <Combobox as="div" v-model="value" v-slot="{open}">
        <div class="relative mt-1">
            <div class="relative w-full bg-white border shadow-sm pr-10 px-8 py-2 text-left cursor-default focus-within:outline-none focus-within:ring-1 focus-within:ring-indigo-500 focus-within:border-indigo-500 sm:text-sm" :class="[
                        open ? 'rounded-t-[2.5rem]' : 'rounded-[2.5rem]',
                        props.error && !props.valid ? 'border-red-300' : 'border-gray-300',
                         {'border-green-500 md:border-gray-300':  props.valid && !error },
                    ]">

                <ComboboxLabel class="block bg-white text-xs text-gray-500">{{ props.label }}</ComboboxLabel>
                <ComboboxInput class="w-full block bg-white text-sm md:text-lg text-gray-900 focus:outline-none" @change="query = $event.target.value" :display-value="(option) => option?.name" />
                <div v-if="props.error"
                     class="absolute transform translate-y-1/2 bg-white bottom-0 left-6 px-2 text-xs text-red-600">
                    {{ props.error }}
                </div>
                <CheckIcon v-if="props.valid"
                           class="hidden md:block absolute top-0 right-[6.5rem] transform translate-y-1/2 h-8 w-8 text-green-500 opacity-50"
                           aria-hidden="true"/>
            </div>

            <ComboboxOptions v-if="filteredOptions.length > 0" class="absolute border-l border-r border-b border-gray-300 z-10 w-full bg-white shadow-lg max-h-60 rounded-b-[2.5rem] py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
                <ComboboxOption v-for="option in filteredOptions" :key="option.value" :value="option" as="template" v-slot="{ active, selected }">
                    <li
                        :class="[
                                    active ? 'text-white bg-indigo-600' : 'text-gray-900',
                                    'cursor-default select-none relative py-2 pl-8 pr-4'
                                ]"
                    >
                        <span
                            :class="[
                                        selected ? 'font-semibold' : 'font-normal',
                                        'block truncate text-base md:text-lg'
                                  ]"
                            >{{ option.name }}</span>

                        <span v-if="selected" :class="['absolute inset-y-0 right-0 flex items-center pr-4', active ? 'text-white' : 'text-indigo-600']">
                          <CheckIcon class="h-5 w-5" aria-hidden="true" />
                        </span>
                    </li>
                </ComboboxOption>
            </ComboboxOptions>
        </div>
    </Combobox>
</template>

<script setup>
import { computed, ref } from 'vue'
import { CheckIcon, SelectorIcon } from '@heroicons/vue/solid'
import {
    Combobox,
    ComboboxButton,
    ComboboxInput,
    ComboboxLabel,
    ComboboxOption,
    ComboboxOptions,
} from '@headlessui/vue'
import {asyncComputed} from "@vueuse/core/index";

const props = defineProps({
    options: {
        type: Array,
        required: true
    },
    label: String,
    error: String,
    valid: {
        type: Boolean,
        default: false
    },
    name: String,
    placeholder: String,
    modelValue: Object
})

const emit = defineEmits(['update:modelValue']);

const computedOptions = asyncComputed(async() => {
    return await props.options
}, [])

const value = computed({
    get() {
        return computedOptions.value?.find(o => o.value === props.modelValue)
    },
    set(value) {
        emit('update:modelValue', value.value)
    }
})


const query = ref('')
const filteredOptions = computed(() => {
    if(!query.value) {
        return []
    }
    return computedOptions.value.filter((option) => {
        return option.name.toLowerCase().includes(query.value.toLowerCase())
    }).sort((a,b) => a < b).slice(0,10)}
);

</script>

<template>
  <div class="mt-1 sm:mt-0">
    <div
        :class="{'border-red-400': props?.error?.length, 'border-gray-300': !props?.error?.length, 'border-green-400': props?.valid}"
        class="flex justify-center rounded-full border-2 border-dashed px-6 pt-5 pb-6">
      <div class="space-y-1 text-center">
        <svg class="mx-auto h-12 w-12 text-gray-400" stroke="currentColor" fill="none" viewBox="0 0 48 48"
             aria-hidden="true">
          <path
              d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
              stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
        <div class="flex text-sm text-gray-600">
          <label class="relative cursor-pointer rounded-full bg-white font-medium text-indigo-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 hover:text-indigo-500">
            <span class="text-md">{{ label }}</span>
            <input @change="addFile" :name="name" type="file" class="sr-only"/>
          </label>
        </div>
        <p v-show="!file?.name" class="text-xs text-gray-500">{{ description }}</p>
        <div v-if="file?.name" class="text-xs text-gray-500 mt-2 inline-flex align-center">
          {{ file.name }}
          <span @click="removeFile()" class="text-red-400 hover:text-red-600 cursor-pointer ml-2">
            <XIcon class="inline h-4 w-4"/> Entfernen
          </span>
        </div>

        <p v-if="props?.error?.length" class="text-sm text-red-400">{{ error }}</p>
      </div>
    </div>
  </div>
</template>

<script setup>
import {XIcon} from '@heroicons/vue/solid'
import {ref} from 'vue'

const props = defineProps({
  label: String,
  error: String,
  description: String,
  valid: {
    type: Boolean,
    default: false
  },
  name: String,
  modelValue: Object
})

const emit = defineEmits(['update:modelValue']);

const file = ref()

const addFile = (event) => {
  file.value = event.target.files[0]
  emit('update:modelValue', file.value)
}

const removeFile = () => {
  file.value = null;
  emit('update:modelValue', null)
}


</script>
